import { Grid, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AvailabilityCheckField } from '../../store/AvailabilityCheck/AvailabilityCheck.actions'
import { ViewType } from '../../store/GeneralState/GeneralState.reducer'
import TestIDs from '../../utils/TestIDs'
import Dropdown from '../Dropdown'
import './customAnchor.css'

export interface AvailabilityCheckInputProps {
    noProductLink: string
    autoFocusOnMount: boolean
    cities: string[]
    disabledCity: boolean
    disabledHouseNumber: boolean
    disabledStreet: boolean
    disabledZip: boolean
    focusedFieldId: AvailabilityCheckField
    houseNumbers: string[]
    selectedCity: string
    selectedHouseNumber: string
    selectedStreet: string
    streets: string[]
    zip: string
    queriesAreFinished?: boolean
    setFocusedField: (payload: AvailabilityCheckField) => void
    setSelectedCity?: (
        payload: React.ChangeEvent<{
            name?: string | undefined
            value: unknown
        }>,
    ) => void
    setSelectedHouseNumber?: (
        payload: React.ChangeEvent<{
            name?: string | undefined
            value: unknown
        }>,
    ) => void
    setSelectedStreet?: (
        payload: React.ChangeEvent<{
            name?: string | undefined
            value: unknown
        }>,
    ) => void
    setZip?: (payload: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    haveLink?: boolean
}

const AvailabilityCheckInput = (props: AvailabilityCheckInputProps): JSX.Element => {
    const { t } = useTranslation()
    const regexp = /^[0-9\b]+$/
    const {
        autoFocusOnMount,
        cities,
        disabledCity,
        disabledHouseNumber,
        disabledStreet,
        disabledZip,
        focusedFieldId,
        houseNumbers,
        selectedCity,
        selectedHouseNumber,
        selectedStreet,
        streets,
        zip,
        queriesAreFinished,
        setFocusedField,
        setSelectedCity,
        setSelectedHouseNumber,
        setSelectedStreet,
        setZip,
        noProductLink,
        haveLink,
    } = props

    const zipRef = useRef<HTMLInputElement>(null)
    const cityRef = useRef<HTMLInputElement>(null)
    const streetRef = useRef<HTMLInputElement>(null)
    const houseNumberRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        switch (focusedFieldId) {
            case AvailabilityCheckField.ZIP:
                zipRef.current?.focus()
                break
            case AvailabilityCheckField.CITY:
                if (cityRef.current) {
                    try {
                        setFocusedField(focusedFieldId)
                        cityRef.current.focus()
                    } catch {}
                }
                break
            case AvailabilityCheckField.STREET:
                if (streetRef.current) {
                    try {
                        setFocusedField(focusedFieldId)
                        streetRef.current.focus()
                    } catch {}
                }
                break
            case AvailabilityCheckField.HOUSE_NUMBER:
                if (houseNumberRef.current) {
                    try {
                        setFocusedField(focusedFieldId)
                        houseNumberRef.current.focus()
                    } catch {}
                }
                break
        }
    }, [focusedFieldId, zip, selectedCity, selectedHouseNumber, selectedStreet])

    const nothingFound = queriesAreFinished === true && cities.length === 0 && zip.length === 5

    return (
        <>
            <Grid container {...TestIDs.GET_AVAILABILITY_CHECK('FORM')} spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        {...TestIDs.GET_AVAILABILITY_CHECK('ZIP_INPUT')}
                        error={nothingFound}
                        disabled={disabledZip}
                        InputProps={{
                            className: nothingFound ? 'TextError' : '',
                            onFocus: (): void => setFocusedField(AvailabilityCheckField.ZIP),
                        }}
                        inputProps={{
                            maxLength: 5,
                        }}
                        inputRef={zipRef}
                        fullWidth={true}
                        label={t('zip')}
                        variant={'outlined'}
                        value={zip}
                        onChange={(e): void => {
                            if (
                                setZip &&
                                ((e.currentTarget.value !== '' && regexp.test(e.currentTarget.value)) ||
                                    e.currentTarget.value === '')
                            ) {
                                setZip(e)
                            }
                        }}
                        autoFocus={autoFocusOnMount}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                    {cities.length === 1 ? (
                        <TextField
                            {...TestIDs.GET_AVAILABILITY_CHECK('CITY_INPUT')}
                            disabled={true}
                            fullWidth={true}
                            label={t('city')}
                            variant={'outlined'}
                            value={cities[0]}
                        />
                    ) : (
                        <Dropdown
                            testIdentifier={{
                                input: 'CITY_SELECT',
                                viewType: ViewType.AVAILABILITY_CHECK,
                            }}
                            label={t('city')}
                            disabled={disabledCity}
                            inputRef={cityRef}
                            value={selectedCity}
                            onChange={setSelectedCity}
                            options={cities}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={8}>
                    {streets.length === 1 ? (
                        <TextField
                            {...TestIDs.GET_AVAILABILITY_CHECK('STREET_INPUT')}
                            disabled={true}
                            fullWidth={true}
                            label={t('street')}
                            variant={'outlined'}
                            value={streets[0]}
                        />
                    ) : (
                        <Dropdown
                            testIdentifier={{
                                input: 'STREET_SELECT',
                                viewType: ViewType.AVAILABILITY_CHECK,
                            }}
                            label={t('street')}
                            disabled={disabledStreet}
                            inputRef={streetRef}
                            value={selectedStreet}
                            onChange={setSelectedStreet}
                            options={streets}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    {houseNumbers.length === 1 ? (
                        <TextField
                            {...TestIDs.GET_AVAILABILITY_CHECK('HOUSE_NUMBER_INPUT')}
                            disabled={true}
                            fullWidth={true}
                            label={t('number')}
                            variant={'outlined'}
                            value={houseNumbers[0]}
                        />
                    ) : (
                        <Dropdown
                            label={t('number')}
                            testIdentifier={{
                                input: 'HOUSE_NUMBER_SELECT',
                                viewType: ViewType.AVAILABILITY_CHECK,
                            }}
                            inputRef={houseNumberRef}
                            disabled={disabledHouseNumber}
                            value={selectedHouseNumber}
                            onChange={setSelectedHouseNumber}
                            options={houseNumbers}
                        />
                    )}
                </Grid>
                {haveLink === true && (
                    <Grid item xs={12}>
                        <Typography
                            variant={'body1'}
                            dangerouslySetInnerHTML={{
                                __html: t('notYetAvailableLink', {
                                    link: noProductLink,
                                }),
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export default AvailabilityCheckInput
